import { useThemeContext } from "../context/theme-context";

const BackgroundColor = ({ className }) => {
  const { themeHandler } = useThemeContext();
  return (
    <div>
      <button
        className={className}
        onClick={() => themeHandler(className)}
      ></button>
    </div>
  );
};

export default BackgroundColor;
