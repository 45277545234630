const data = [
  { id: 1, link: "#", title: "Home" },
  { id: 2, link: "#about", title: "About" },
  { id: 3, link: "#services", title: "Services" },
  { id: 4, link: "#portfolio", title: "Portfolio" },
  { id: 5, link: "#contact", title: "Contact" },
  { id: 6, link: "https://medium.com/@anishmachamasi2262", title: "My Blogs" },
];

export default data;
